import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import type { ContentProps } from './Content';

export const useCustomStyles = makeStyles<ContentProps['flexDirection']>()((theme, flexDirectionValue) => ({
	generalContainer: {
		padding: `0 ${theme.spacing.small}`,

		[getFromBreakpoint('xl')]: {
			padding: 0,
		},
	},

	container: {
		boxSizing: 'border-box',
		maxWidth: `calc(${theme.container.width.lg} + 2*${theme.spacing.small})`,
		marginRight: 'auto',
		marginLeft: 'auto',

		[getFromBreakpoint('md')]: {
			padding: `0 ${theme.spacing.small}`,
		},

		[getFromBreakpoint('xl')]: {
			padding: `0 ${theme.spacing.small}`,
		},
	},

	fullWidth: {
		maxWidth: theme.container.width.full,
		padding: `0 ${theme.spacing.xxsmall}`,

		[getFromBreakpoint('md')]: {
			padding: `0 ${theme.spacing.small}`,
		},
	},

	/** We currently use fullWidth and withSpacing classes to support content pages.
	 * We provide options for these in Contentful. */
	withSpacing: {
		maxWidth: theme.container.width.md,
		margin: '0 auto',
	},

	flexContainer: {
		display: 'flex',
		flexDirection: 'column',

		[getFromBreakpoint('md')]: {
			flexDirection: flexDirectionValue || 'row',
		},
	},

	/** Class for Storybook content */
	storyClass: {
		background: theme.palette.carrotOrange[120],
		color: theme.palette.teal[20],
	},

	fullHeight: {
		width: '100%',
		flex: 1,
	},
}));
